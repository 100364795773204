<em class="fas fa-times cerrar-modal" (click)="closeModal()"></em>
<h4 mat-dialog-title class="titulo-modal">Ingresar medición de Peso</h4>
<mat-dialog-content style="max-width: 500px !important; padding: 0 .5rem !important;">
    <ng-container [formGroup]="form">
        <div class="azh-col-row">
            <div class="azh-col--6">
                <div class="form-group">
                    <label for="weight" class="form-label">Peso (kg)</label>
                    <input autocomplete="off" type="text" class="form-control" id="weight" formControlName="weight"
                        (keyup)="validatorUtils.onlyNumberAndPoints($event,true); getBmi()"
                        (keydown)="validatorUtils.onlyNumberAndPoints($event,true)">
                </div>
            </div>
            <div class="azh-col--6">
                <div class="form-group">
                    <label for="bmi" class="form-label">IMC (kg/m2)</label>
                    <input autocomplete="off" type="text" class="form-control" id="bmi" formControlName="bmi" readonly="true">
                </div>
            </div>
        </div>
        <div class="azh-col-row">
            <div class="azh-col--12">
                <h4 class="titulo-modal"><strong>Datos opcionales</strong> </h4>
            </div>
            <div class="azh-col--6">
                <div class="form-group">
                    <label for="bodyFat" class="form-label">Grasa corporal (%)</label>
                    <input autocomplete="off" type="text" class="form-control" id="bodyFat" formControlName="bodyFat"
                        (keyup)="validatorUtils.onlyNumberAndPoints($event,true)"
                        (keydown)="validatorUtils.onlyNumberAndPoints($event,true)">
                </div>
            </div>
            <div class="azh-col--6">
                <div class="form-group">
                    <label for="muscleMass" class="form-label">Masa muscular (kg)</label>
                    <input autocomplete="off" type="text" class="form-control" id="muscleMass" formControlName="muscleMass"
                        (keyup)="validatorUtils.onlyNumberAndPoints($event,true)"
                        (keydown)="validatorUtils.onlyNumberAndPoints($event,true)">
                </div>
            </div>
            <div class="azh-col--6">
                <div class="form-group">
                    <label for="bodyWater" class="form-label">Agua corporal (%)</label>
                    <input autocomplete="off" type="text" class="form-control" id="bodyWater" formControlName="bodyWater"
                        (keyup)="validatorUtils.onlyNumberAndPoints($event,true)"
                        (keydown)="validatorUtils.onlyNumberAndPoints($event,true)">
                </div>
            </div>
            <div class="azh-col--6">
                <div class="form-group">
                    <label for="boneMass" class="form-label">Masa ósea (kg)</label>
                    <input autocomplete="off" type="text" class="form-control" id="boneMass" formControlName="boneMass"
                        (keyup)="validatorUtils.onlyNumberAndPoints($event,true)"
                        (keydown)="validatorUtils.onlyNumberAndPoints($event,true)">
                </div>
            </div>
        </div>
        <div class="azh-col-row">
            <div class="azh-col--12">
                <div class="form-group">
                    <label for="commet" class="form-label">Comentario</label>
                    <input autocomplete="off" type="text" class="form-control" id="commet" formControlName="commet">
                </div>
            </div>
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end"> 
    <button (click)="closeModal()" type="button" class="button-app button-app--first-color mr-1">Cancelar</button>
    <button [disabled]="form.invalid || loadService" (click)="form.valid ? submit() : ''" type="button" class="button-app button-app--first-color">Guardar
    </button>
</mat-dialog-actions>