import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { JsonResult } from '../../shared/interfaces/json-result.interface';

@Injectable({
    providedIn: 'root'
})
export class PatientService {
    private headers!: HttpHeaders;
    private url: string;


    constructor(
        private _http: HttpClient,
    ) {
        this.url = `${environment.URL_MOBILE}`;
    }

    getDataPatient(token: string): Observable<JsonResult> | any {
        this.headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        });
        return this._http.get<any>(`${this.url}services.paciente/userinfo`, { headers: this.headers });
    }

    getDataDailyMeasurement(type:string, token: string, urlExtra: string = ''): Observable<JsonResult> | any {
        this.headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        });
        return this._http.get<any>(`${this.url}services.paciente/daily-line-chart/${type}${urlExtra}`, { headers: this.headers });
    }

    getDataTimeMeasurement(type:string, token: string, urlExtra: string = ''): Observable<JsonResult> | any {
        this.headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        });
        return this._http.get<any>(`${this.url}services.paciente/time-line-chart/${type}${urlExtra}`, { headers: this.headers });
    }
    
    getDataYearMeasurement(type:string, token: string): Observable<JsonResult> | any {
        this.headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        });
        return this._http.get<any>(`${this.url}services.paciente/anual-line-chart/${type}`, { headers: this.headers });
    }

    getHistoryMeasurements(token: string, type: string, page: number, pagesize: number): Observable<JsonResult> | any {
        this.headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        });
        return this._http.get<any>(`${this.url}services.paciente/history-paginate/${type}/${((page == 0) ? 1 : (page + 1))}/${pagesize}`, { headers: this.headers });
    }

    getDataDailyAverageGlucose(token: string): Observable<JsonResult> | any {
        this.headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        });
        return this._http.get<any>(`${this.url}services.paciente/daily-average-blood-glucose`, { headers: this.headers });
    }
}
