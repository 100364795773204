<div class="page-content">
    <mat-tab-group animationDuration="0ms" dynamicHeight class="pb-2">
        <mat-tab label="Gráficos">
            <mat-tab-group [selectedIndex]="selected" (selectedIndexChange)="selected = $event"
                (selectedTabChange)="tabSelectedParent($event)">
                <mat-tab label="30 Días">
                </mat-tab>
                <mat-tab label="Año">
                </mat-tab>
            </mat-tab-group>
            <div [class]="'azh-col-row center pb-4 paddingChart ' + ((selected == 1) ? 'no-visible':'')">
                <ng-container *ngFor="let item of weightData; let i = index">
                    <div class="col--12 mt-1">
                        <h3 class="left bold">{{item.name}}</h3>
                    </div>
                    <div class="col--12 shadowBox p-1">
                        <div class="col--12" *ngIf="!loadingService[item.type]">
                            <div class="col--12 maxChartLine">
                                <ng-container *ngIf="stateChart.chart1 == true">
                                    <ngx-charts-line-chart [scheme]="colorScheme" [legend]="legend"
                                        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                        [xAxis]="xAxis" [yAxis]="yAxis" [xAxisLabel]="xAxisLabel"
                                        [yAxisLabel]="yAxisLabel" [timeline]="timeline" [results]="multi[item.type]"
                                        [yAxisTicks]="[item.min, item.max]" [yScaleMin]="item.min"
                                        [yScaleMax]="item.max">
                                    </ngx-charts-line-chart>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col--12 maxChartLine pt-4" *ngIf="loadingService[item.type]">
                            <em class="fa fa-circle-o-notch fa-spin fa-5x "></em>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div [class]="'azh-col-row center pb-4 paddingChart ' + ((selected == 0) ? 'no-visible':'')">
                <ng-container *ngFor="let itemYear of weightDataYear; let i = index">
                    <div class="col--12 mt-1">
                        <h3 class="left bold">{{itemYear.name}}</h3>
                    </div>
                    <div class="col--12 shadowBox p-1">
                        <div class="col--12" *ngIf="!loadingService[itemYear.type+'_year']">
                            <div class="col--12 maxChartLine">
                                <ng-container *ngIf="stateChart.chart2 == true">
                                    <ngx-charts-line-chart [scheme]="colorScheme" [legend]="legend"
                                        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                        [xAxis]="xAxis" [yAxis]="yAxis" [xAxisLabel]="xAxisLabel"
                                        [yAxisLabel]="yAxisLabel" [timeline]="timeline"
                                        [results]="multiYear[itemYear.type]" [yAxisTicks]="[itemYear.min, itemYear.max]"
                                        [yScaleMin]="itemYear.min" [yScaleMax]="itemYear.max">
                                        <ng-template #tooltipTemplate let-model="model">
                                            <span class="tooltip-label">{{ model.label }}</span>
                                            <span class="tooltip-val">{{ model.value | number }}</span>
                                        </ng-template>
                                    </ngx-charts-line-chart>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col--12 maxChartLine pt-4" *ngIf="loadingService[itemYear.type+'_year'] || !stateChart.chart2">
                            <em class="fa fa-circle-o-notch fa-spin fa-5x "></em>
                        </div>
                    </div>
                </ng-container>
            </div>
        </mat-tab>
        <mat-tab label="Listado">
            @if (itemList.length > 0) {
            <div class="azh-col-row center pb-4" infiniteScroll [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="300" (scrolled)="onScroll()">
                <div class="col--12 itemList" *ngFor="let item of itemList; let i = index">
                    <div class="col--12 itemHeader left">
                        {{ item.date }}
                    </div>
                    <div class="col--3 itemMeasure">
                        <div>
                            <strong>{{ item.value }}</strong><br>
                            {{ item.unidad }}
                        </div>
                    </div>
                    <div class="col--6 itemMeasureExtra left">
                        <ng-container
                            *ngIf="item.data_extra != undefined && searchItemExtra(11, item.data_extra) != null">
                            <div [class]="searchItemExtra(11, item.data_extra)?.labelHealth +'Item'"></div>
                            <strong>{{ searchItemExtra(11, item.data_extra)?.value
                                }}</strong> {{ searchItemExtra(11, item.data_extra)?.unidad }}<br>
                        </ng-container>
                        <ng-container
                            *ngIf="item.data_extra != undefined && searchItemExtra(14, item.data_extra) != null">
                            <strong>{{ searchItemExtra(14, item.data_extra)?.value
                                }}</strong>{{ searchItemExtra(14, item.data_extra)?.unidad }} {{ searchItemExtra(14,
                            item.data_extra)?.despliegue }}
                        </ng-container>
                    </div>
                    <div class="col--3 itemTime">
                        {{ item.time }}
                    </div>
                    <div class="col--12 itemFooter left" *ngIf="item.optional_note!=''">
                        <em class="fa fa-sticky-note-o"></em> {{ item.optional_note }}
                    </div>
                </div>
                <div class="col--12 pt-1" *ngIf="loadingScroll">
                    <em class="fa fa-circle-o-notch fa-spin fa-2x"></em>
                </div>
            </div>
            } @else {
            <div class="azh-col-row center">
                <div class="col--12 pt-4 " *ngIf="!loadingScroll">
                    <em class="fas fa-4x fa-table opacity-65"></em>
                    <h2 class="pt-1 opacity-65">Sin mediciones registradas</h2>
                </div>
                <div class="col--12 pt-4" *ngIf="loadingScroll">
                    <em class="fa fa-circle-o-notch fa-spin fa-2x"></em>
                </div>
            </div>
            }
        </mat-tab>
    </mat-tab-group>



</div>