import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ScreenWeightComponent } from './measure/screen-weight/screen-weight.component';
import { ScreenGlucoseComponent } from './measure/screen-glucose/screen-glucose.component';
import { ScreenPresionComponent } from './measure/screen-presion/screen-presion.component';
import { ScreenHomeComponent } from './measure/home.component';

export const routes: Routes = [
    {
        path: "",
        component: HomeComponent,
    },
    {
        path: "auth/login",
        component: LoginComponent,
    },
    {
        path: "screen/home",
        component: ScreenHomeComponent,
    },
    {
        path: "screen/weight",
        component: ScreenWeightComponent,
    },
    {
        path: "screen/glucose",
        component: ScreenGlucoseComponent,
    },
    {
        path: "screen/presion",
        component: ScreenPresionComponent,
    },
    {
        path: "",
        pathMatch: "full",
        redirectTo: "/",
    },
    {
        path: "**",
        pathMatch: "full",
        redirectTo: "/",
    }
];
