<em class="fas fa-times cerrar-modal" (click)="closeModal()"></em>
<h4 mat-dialog-title class="titulo-modal">Ingresar medición de Glicemia</h4>
<mat-dialog-content style="max-width: 500px !important; padding: 0 .5rem !important;">
    <ng-container [formGroup]="form">
        <div class="azh-col-row">
            <div class="azh-col--6">
                <div class="form-group">
                    <label for="bloodSugar" class="form-label">Glicemia</label>
                    <input autocomplete="off" type="text" class="form-control" id="bloodSugar" formControlName="bloodSugar"
                        (keyup)="validatorUtils.onlyNumberAndPoints($event,true)"
                        (keydown)="validatorUtils.onlyNumberAndPoints($event,true)">
                </div>
            </div>
            <div class="azh-col--6">
                <div class="form-group">
                    <label for="timeMeasurement" class="form-label">Tiempo de Medición</label>
                    <select formControlName="timeMeasurement" class="form-control">
                        <option value="" disabled></option>
                        <option value="1">Ayuno</option>
                        <option value="2">Antes del desayuno</option>
                        <option value="3">Después del desayuno</option>
                        <option value="4">Antes del almuerzo</option>
                        <option value="5">Después del almuerzo</option>
                        <option value="6">Antes de cenar</option>
                        <option value="7">Después de cenar</option>
                    </select>
                </div>
            </div>
            <div class="azh-col--12">
                <div class="form-group">
                    <label for="commet" class="form-label">Comentario</label>
                    <input autocomplete="off" type="text" class="form-control" id="commet" formControlName="commet">
                </div>
            </div>
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="closeModal()" type="button" class="button-app button-app--first-color mr-1">Cancelar</button>
    <button [disabled]="form.invalid || loadService" type="button" (click)="form.valid ? submit() : ''"
        class="button-app button-app--first-color">Guardar
    </button>
</mat-dialog-actions>