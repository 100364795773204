import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, NO_ERRORS_SCHEMA, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { HomeService } from '../services/home.services';
import { JsonResult } from '../../shared/interfaces/json-result.interface';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';
import { DataExtra } from '../../shared/interfaces/patient.interface';

@Component({
  selector: 'app-historial',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule, MatDialogModule, MatTableModule, MatPaginatorModule, HttpClientModule],
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.css'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: []
})
export class HistorialComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  result: any[] = [];
  rows = new MatTableDataSource<any[]>(this.result);
  itemsPerPage: number = 10;
  currentPage: number = 0;
  totalItems: number = 0;
  columns: any[] = [];
  title: string = '';
  loading: boolean = true;
  type: number = 0;
  element: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    private dialogRef: MatDialogRef<HistorialComponent>,
    private homeService: HomeService
  ) { }

  ngOnInit() {
    if (this.data?.tipo == 'weight' || this.data?.tipo == 20) {
      this.title = 'Peso';
      this.type = 20;
      this.columns = ['Peso', 'IMC', 'Grasa corporal', 'Masa ósea', 'Masa muscular', 'Agua Corporal', 'Fecha'];
    } else if (this.data?.tipo == 'glycemia' || this.data?.tipo == 10) {
      this.title = 'Glicemia';
      this.type = 10;
      this.columns = ['Resultado', 'Momento de medición', 'Fecha'];

    } else if (this.data?.tipo == 'pressure' || this.data?.tipo == 57) {
      this.title = 'Presión arterial';
      this.type = 57;
      this.columns = ['Sistólica', 'Diastólica', 'Pulso', 'Fecha'];
    }
    this.getMeasurements(this.type);
    this.paginator._intl.firstPageLabel = "Primera página";
    this.paginator._intl.itemsPerPageLabel = "Registros Por Pagina";
    this.paginator._intl.lastPageLabel = "Última página";
    this.paginator._intl.nextPageLabel = "Página siguiente";
    this.paginator._intl.previousPageLabel = "Página anterior";
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
      if (length === 0) {
        return `Pag 1 de 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return `Pag ${page + 1} de ${amountPages}`;
    }
    this.rows.paginator = this.paginator;
  }

  getMeasurements(type: number) {
    this.loading = true;
    this.homeService.getMeasurements(this.data?.data.idPaciente, type, this.currentPage, this.itemsPerPage)
      .subscribe((res: JsonResult) => {
        this.result = [];
        for (const trae of res.retObject.data) {
          trae.dateMeasurements = trae.date + " " + trae.time;
          trae.text = trae.value + " " + trae.unidad;
          trae.data_extra = trae.data_extra || []
          this.result.push(trae)
        }
        this.totalItems = res.retObject.total;
        this.rows = new MatTableDataSource<any[]>(this.result);
        this.loading = false;

      }, (error: any) => {
        this.loading = false;
        console.error(error);
      });
  }

  handlePageEvent(e: PageEvent) {
    this.totalItems = e.length;
    this.itemsPerPage = e.pageSize;
    this.currentPage = e.pageIndex;
    this.getMeasurements(this.type);
  }
  searchItemExtra(id: number, items: DataExtra[]): DataExtra {
    for (const item of items) {
      if (item.id == id) {
        item.text = (item.value + " " + item.unidad).replace(" %", "%");
        return item;
      }
    }
    return { id: 0, value: 0, unidad: "", text: "---" } as DataExtra;
  }
  dismiss() {
    this.dialogRef.close();
  }


}
