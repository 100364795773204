<div *ngIf="isLoading" class="loader loader--fullscreen">
    <div class="logo-animated">
        <svg class="heart-latir" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.41 99.667">
            <defs>
                <style>
                    .cls-1 {
                        fill: #a87fd2;
                    }
                </style>
            </defs>
            <path id="loader-fullscreen-heart-full" class="cls-1"
                d="M112.811,41.431a31.416,31.416,0,0,0-24.922,12.32A31.315,31.315,0,0,0,31.68,72.407c0,34.691,52.763,66.971,54.991,68.345a2.623,2.623,0,0,0,1.217.345,2.532,2.532,0,0,0,1.217-.345c2.257-1.375,54.984-33.655,54.984-68.345A31.175,31.175,0,0,0,112.811,41.431Z"
                transform="translate(-31.68 -41.431)" />
        </svg>

        <svg class="heart-animated" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.668 100">
            <defs>
                <style>
                    .cls-1 {
                        fill: #a87fd2;
                    }

                    .cls-2 {
                        fill: #fff;
                    }

                    .fase-1 {
                        fill: transparent;
                        stroke-dashoffset: 0;
                        stroke-dasharray: 0;
                        stroke: transparent;
                        stroke-width: 3px;
                        animation: linecomplete 2s forwards;
                    }

                    .fase-2 {
                        opacity: 0;
                        animation: showHeart 1s 1s forwards;
                    }

                    @keyframes linecomplete {
                        0% {
                            stroke: #a87fd2;
                            stroke-dasharray: 0;
                        }

                        50% {
                            stroke: #a87fd2;
                            stroke-dasharray: 340;
                        }

                        55%,
                        60% {
                            stroke-dasharray: 340;
                            fill: #a87fd2;
                            stroke: transparent;
                            stroke-width: 0;
                            opacity: 1;
                        }

                        65%,
                        90% {
                            opacity: 1;
                        }

                        95%,
                        100% {
                            opacity: 0;
                        }
                    }

                    @keyframes showHeart {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }
                </style>
            </defs>
            <g id="loader-fullscreen-stroke" transform="translate(-31.68 -41.431)">
                <!-- fase 1 -->
                <path id="Trazado_219" data-name="Trazado 219" class="cls-1 fase-1"
                    d="M112.276,41.431A31.1,31.1,0,0,0,87.518,53.792,31.045,31.045,0,0,0,31.68,72.511c0,34.807,52.415,67.195,54.629,68.574a2.586,2.586,0,0,0,1.209.346,2.5,2.5,0,0,0,1.209-.346c2.242-1.379,54.621-33.767,54.621-68.574A31.122,31.122,0,0,0,112.276,41.431ZM87.518,136.318c-7.878-5.043-51.177-34.39-51.177-63.808a26.4,26.4,0,0,1,49.176-13.4,2.386,2.386,0,0,0,4,0,26.4,26.4,0,0,1,49.169,13.4C138.688,101.929,95.388,131.276,87.518,136.318Z"
                    transform="translate(0 0)" />
                <path id="Trazado_220" data-name="Trazado 220" class="cls-1 fase-1"
                    d="M129.193,102.114h-8.868V93.284a6.248,6.248,0,0,0-6.253-6.254h-5.666a6.248,6.248,0,0,0-6.254,6.254v8.831H93.284a6.263,6.263,0,0,0-6.254,6.321V114.1a6.19,6.19,0,0,0,6.254,6.186h8.868v8.9a6.248,6.248,0,0,0,6.254,6.253h5.666a6.248,6.248,0,0,0,6.253-6.253v-8.9h8.868a6.223,6.223,0,0,0,6.291-6.186v-5.666A6.294,6.294,0,0,0,129.193,102.114Zm1.318,11.987a1.3,1.3,0,0,1-1.318,1.251H117.861a2.473,2.473,0,0,0-2.464,2.5v11.332a1.323,1.323,0,0,1-1.326,1.326h-5.666a1.287,1.287,0,0,1-1.288-1.326V117.853a2.486,2.486,0,0,0-2.5-2.5H93.284A1.271,1.271,0,0,1,92,114.1v-5.666a1.285,1.285,0,0,1,1.288-1.318h11.332a2.535,2.535,0,0,0,2.5-2.5V93.284a1.287,1.287,0,0,1,1.288-1.326h5.666a1.323,1.323,0,0,1,1.326,1.326v11.332a2.522,2.522,0,0,0,2.464,2.5h11.332a1.315,1.315,0,0,1,1.318,1.318Z"
                    transform="translate(-23.723 -19.803)" />

                <!-- fase-2 -->
                <path id="Trazado_219" data-name="Trazado 219" class="cls-1 fase-2"
                    d="M112.813,41.431A31.31,31.31,0,0,0,87.89,53.874,31.252,31.252,0,0,0,31.68,72.718c0,35.039,52.764,67.643,54.993,69.031a2.6,2.6,0,0,0,1.217.349,2.513,2.513,0,0,0,1.217-.349c2.257-1.388,54.986-33.992,54.986-69.031A31.33,31.33,0,0,0,112.813,41.431Z"
                    transform="translate(0 0)" />
                <path id="Trazado_220" data-name="Trazado 220" class="cls-2 fase-2"
                    d="M129.193,102.114h-8.868V93.284a6.248,6.248,0,0,0-6.253-6.254h-5.666a6.248,6.248,0,0,0-6.254,6.254v8.831H93.284a6.263,6.263,0,0,0-6.254,6.321V114.1a6.19,6.19,0,0,0,6.254,6.186h8.868v8.9a6.248,6.248,0,0,0,6.254,6.253h5.666a6.248,6.248,0,0,0,6.253-6.253v-8.9h8.868a6.223,6.223,0,0,0,6.291-6.186v-5.666A6.294,6.294,0,0,0,129.193,102.114Zm1.318,11.987a1.3,1.3,0,0,1-1.318,1.251H117.861a2.473,2.473,0,0,0-2.464,2.5v11.332a1.323,1.323,0,0,1-1.326,1.326h-5.666a1.287,1.287,0,0,1-1.288-1.326V117.853a2.486,2.486,0,0,0-2.5-2.5H93.284A1.271,1.271,0,0,1,92,114.1v-5.666a1.285,1.285,0,0,1,1.288-1.318h11.332a2.535,2.535,0,0,0,2.5-2.5V93.284a1.287,1.287,0,0,1,1.288-1.326h5.666a1.323,1.323,0,0,1,1.326,1.326v11.332a2.522,2.522,0,0,0,2.464,2.5h11.332a1.315,1.315,0,0,1,1.318,1.318Z"
                    transform="translate(-23.723 -19.803)" />
            </g>
        </svg>
    </div>
    <p class="title">Cargando...</p>
</div>