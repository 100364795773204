<em class="fas fa-times cerrar-modal" (click)="closeModal()"></em>
<h4 mat-dialog-title class="titulo-modal">Ingresar medición de Presión arterial</h4>
<mat-dialog-content style="max-width: 500px !important; padding: 0 .5rem !important;">
    <ng-container [formGroup]="form">
        <div class="azh-col-row">
            <div class="azh-col--12">
                <div class="form-group">
                    <label for="systolic" class="form-label">Sistólica</label>
                    <input autocomplete="off" type="text" class="form-control" id="systolic" formControlName="systolic"
                        (keyup)="validatorUtils.onlyNumberAndPoints($event,true)"
                        (keydown)="validatorUtils.onlyNumberAndPoints($event,true)">
                </div>
            </div>
            <div class="azh-col--12">
                <div class="form-group">
                    <label for="diastolic" class="form-label">Diastólica</label>
                    <input autocomplete="off" type="text" class="form-control" id="diastolic" formControlName="diastolic"
                        (keyup)="validatorUtils.onlyNumberAndPoints($event,true)"
                        (keydown)="validatorUtils.onlyNumberAndPoints($event,true)">
                </div>
            </div>
            <div class="azh-col--12">
                <div class="form-group">
                    <label for="pulse" class="form-label">Pulso</label>
                    <input autocomplete="off" type="text" class="form-control" id="pulse" formControlName="pulse"
                        (keyup)="validatorUtils.onlyNumberAndPoints($event,true)"
                        (keydown)="validatorUtils.onlyNumberAndPoints($event,true)">
                </div>
            </div>
            <div class="azh-col--12">
                <div class="form-group">
                    <label for="commet" class="form-label">Comentario</label>
                    <input autocomplete="off" type="text" class="form-control" id="commet" formControlName="commet">
                </div>
            </div>
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="closeModal()" type="button" class="button-app button-app--first-color mr-1">Cancelar</button>
    <button [disabled]="form.invalid || loadService" (click)="form.valid ? submit() : ''" type="button"
        class="button-app button-app--first-color">Guardar
    </button>
</mat-dialog-actions>