import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AzLoadersModule } from '../shared/az-loaders';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-screen-home',
    standalone: true,
    imports: [CommonModule, HttpClientModule, AzLoadersModule],
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers: []
})
export class ScreenHomeComponent implements OnInit {
    loading: boolean = true;
    items: any[] = []
    constructor(
        private activatedRouter: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.activatedRouter.queryParams.subscribe((trae) => {
            let { tokenApp } = trae;
            this.items = [
                {
                    icon: "fa fa-tint",
                    url: "screen/glucose?tokenApp=" + tokenApp,
                    name: "Glicemia"
                },
                {
                    icon: "fa fa-weight",
                    url: "screen/weight?tokenApp=" + tokenApp,
                    name: "Peso"
                },
                {
                    icon: "fa fa-heartbeat",
                    url: "screen/presion?tokenApp=" + tokenApp,
                    name: "Presión arterial"
                }
            ]
        });
        setTimeout(() => {
            this.loading = false
        }, 2000);
    }
    go(url: string) {
        this.router.navigateByUrl(url);
    }
}
