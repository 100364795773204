@if (loading) {
<az-loader-fullscreen [show-loading]="true"></az-loader-fullscreen>
} @else {
<div class="page-content">
    <h1 class="bold left mt-2 ml-2 mr-2">Historial</h1>
    <p class="ml-2 mr-2">Consulta el historial de las mediciones que has realizado en el tiempo.</p>
    <div class="azh-col-row center">
        <div (click)="go(item.url)" class="col--10 shadow m-1 pt-1 pb-1" *ngFor="let item of items; let i = index">
            <div class="col--1">
            </div>
            <div class="col--2 p-1 iconMeasure">
                <em [class]="item.icon"></em>
            </div>
            <div class="col--9 p-1">
                <h4 class="bold left">{{ item.name }}</h4>
            </div>
        </div>
    </div>
</div>
}