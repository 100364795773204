import { Injectable } from "@angular/core";
import moment from "moment";

@Injectable({
    providedIn: "root"
})
export class ValidatorsUtils {
    constructor() { }

    public onlyTextAndSpaces(event: any) {
        event.target.value = event.target.value.trimStart();
        event.target.value = event.target.value.replace(/[`1234567890`~!°@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        if (event.key === '-' && event.target.value.length === 1) {
            event.target.value = "";
        }
        let regex = /^[a-zA-Z\u00C0-\u017F -]+$/;
        if (regex.test(event.key) || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab' || event.key === 'Delete') {
            return true;
        } else {
            return false;
        }
    }

    public onlyTextNumberAndSpaces(event: any) {
        event.target.value = event.target.value.trimStart();
        event.target.value = event.target.value.replace(/[`~!$%^&*()|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        if (event.key === '-' && event.target.value.length === 1) {
            event.target.value = "";
        }
        let regex = /^[a-zA-Z0-9\u00C0-\u017F °@#_-]+$/;
        if (regex.test(event.key) || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab' || event.key === 'Delete') {
            return true;
        } else {
            return false;
        }
    }

    public onlyNumberAndPoints(event: any, validpoint: boolean = false, validcoma: boolean = false) {
        event.target.value = event.target.value.trim();
        let regex = validpoint && !validcoma ? /^[0-9\.]$/ : !validpoint && validcoma ? /^[0-9\,]$/ : validpoint && validcoma ? /^[0-9\,.]$/ : /^[0-9]$/;
        if (regex.test(event.key) || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab' || event.key === 'Delete') {
            if (validpoint && !validcoma) {
                if (event.target.value.includes('.') && event.key === '.') {
                    return false;
                } else {
                    return true;
                }
            } else if (!validpoint && validcoma) {
                return true;
            } else if (validpoint && validcoma) {
                if (event.target.value.includes('.') && event.key === '.') {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    public calcMinAndMAx(weightData: any, type: string, data: any[], option: number = 1, applyZero: boolean = false) {
        let indexCach: number | string = -1;

        if (option == 1) {
            for (const [index, element] of weightData.entries()) {
                if (element.type == type) {
                    indexCach = index;
                    break;
                }
            }

            if (indexCach == null) return;
        } else {
            indexCach = type;
        }

        if (data.length == 1 && Number(data[0].y || data[0].value) > 0) {
            weightData[indexCach].max = Number((data[0].y || data[0].value).toFixed(0)) + 5
            weightData[indexCach].min = Number(weightData[indexCach].max / 2)
        } else if (data.length > 1) {
            let mayor = 0;
            let minValue = 0;
            let maxValue = 0;
            for (const i of data) {
                if (Number(i.y || i.value) > 0) {
                    mayor = Number(i.y || i.value)
                    minValue = mayor;
                    maxValue = mayor;
                    break;
                }
            }
            if (mayor > 0) {
                for (const i of data) {
                    if (Number(i.y || i.value) > 0) {
                        if (Number(i.y || i.value) < minValue) {
                            minValue = Number(i.y || i.value);
                        }
                        if (Number(i.y || i.value) > maxValue) {
                            maxValue = Number(i.y || i.value);
                        }
                    } else {
                        minValue = 0;
                    }
                }
                if (minValue == 0 && maxValue > 0 && applyZero) {
                    minValue = (maxValue / 2);
                }
                let min = Number(minValue.toFixed(0)) - 2
                weightData[indexCach].min = ((min <= 0) ? 0 : min)
                weightData[indexCach].max = Number(maxValue.toFixed(0)) + 10
            }
        }
    }
    // Método para calcular el porcentaje de grasa corporal
    public calcBodyFat = ({
        height = 0,
        weight = 0,
        genre = "-",
        age = 0,
    }) => {
        height = height / 100;
        return age <= 0 || weight <= 0 ? 0 : Number(((1.20 * (weight / (height * height))) + (0.23 * age) - (10.8 * (genre == "M" ? 1 : 2)) - 5.4).toFixed(2));
    }
    // Método para calcular la masa muscular
    public calcBodyMassMuscle = ({
        height = 0,
        weight = 0,
        genre = "-",
        age = 0,
    }) => {
        let grasaCorporal = this.calcBodyFat({
            height,
            weight,
            genre,
            age
        });
        if (genre == 'M') {
            return Number(((weight * (1 - grasaCorporal / 100)) * 0.85).toFixed(2));
        } else if (genre == 'F') {
            return Number(((weight * (1 - grasaCorporal / 100)) * 0.75).toFixed(2));
        } else {
            return 0;
        }
    }
    // Método para calcular el porcentaje de agua corporal
    public calcBodyWater = ({
        weight = 0,
        genre = "-",
    }) => {
        if (genre == 'M') {
            return Number((0.60 * weight).toFixed(2));
        } else if (genre == 'F') {
            return Number((0.50 * weight).toFixed(2));
        } else {
            return 0;
        }
    }
    // Método para calcular la masa ósea
    public calcBodyMassBone = ({
        weight = 0,
        genre = "-",
    }) => {
        if (genre == 'M') {
            return Number((weight * 0.15).toFixed(2));
        } else if (genre == 'F') {
            return Number((weight * 0.12).toFixed(2));
        } else {
            return 0;
        }
    }
    // Método para calcular IMC 
    public calcIMC = (weight: string | number, height: string | number) => {
        weight = !weight || weight == '' ? 0 : weight;
        height = !height || height == '' ? 0 : height;
        height = Number(height) / 100;
        let imc = height == 0 || height == Infinity ? 0 : Number(weight) / (height * height);
        return Number(imc.toFixed(2));
    }
}