<div class="wrapper-auth">
    <form [formGroup]="form" class="form">
        <img class="logo" src="assets/img/logo-azh.svg" alt="Logo - Azurian Health">

        <p class="subtitle-app text-center mt-4 mb-4">Iniciar sesión</p>

        <div class="form-group" [class]="form.get('rut')?.errors && form.get('rut')?.touched ? 'no-valid' : ''"
            [attr.message-error]="f['rut'].errors?.required ? 'El RUT es requerido.' : f['rut'].errors?.pattern ? 'El RUT ingresado no es valido.' : ''">
            <label class="form-label">RUT</label>
            <input type="text" class="form-control" formControlName="rut" placeholder="1-9">
        </div>

        <div class="w-100 flex xCenter yCenter mt-4">
            <button [disabled]="form.invalid || loadService"
                class="button-app button-app--second-color" (click)="form.valid ? login() : ''">Ingresar</button>
        </div>
    </form>
</div>