<h4 mat-dialog-title class="titulo-modal">
    {{title}}
</h4>
<mat-dialog-content style="max-width: 1000px !important; padding: 0 .5rem !important;">
    <div class="azh-col-row">
        <div class="azh-col--12">
            <table mat-table [dataSource]="rows" aria-describedby="History medtions results">

                <ng-container *ngIf="type == 10;">
                    <ng-container matColumnDef="Resultado">
                        <th mat-header-cell *matHeaderCellDef> Resultado </th>
                        <td mat-cell *matCellDef="let element"> {{element.text}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Momento de medición">
                        <th mat-header-cell *matHeaderCellDef> Momento de medición </th>
                        <td mat-cell *matCellDef="let element"> {{element.type_tm}} </td>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="type == 20;">
                    <ng-container matColumnDef="Resultado">
                        <th mat-header-cell *matHeaderCellDef> Resultado </th>
                        <td mat-cell *matCellDef="let element"> {{element.text}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Peso">
                        <th mat-header-cell *matHeaderCellDef> Peso </th>
                        <td mat-cell *matCellDef="let element"> {{element.text}} </td>
                    </ng-container>
                    <ng-container matColumnDef="IMC">
                        <th mat-header-cell *matHeaderCellDef> IMC </th>
                        <td mat-cell *matCellDef="let element"> {{ searchItemExtra(11, element.data_extra).text }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Grasa corporal">
                        <th mat-header-cell *matHeaderCellDef> Grasa corporal </th>
                        <td mat-cell *matCellDef="let element"> {{ searchItemExtra(14, element.data_extra).text }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Masa ósea">
                        <th mat-header-cell *matHeaderCellDef> Masa ósea </th>
                        <td mat-cell *matCellDef="let element"> {{ searchItemExtra(67, element.data_extra).text }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Masa muscular">
                        <th mat-header-cell *matHeaderCellDef> Masa muscular </th>
                        <td mat-cell *matCellDef="let element"> {{ searchItemExtra(68, element.data_extra).text }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Agua Corporal">
                        <th mat-header-cell *matHeaderCellDef> Agua Corporal </th>
                        <td mat-cell *matCellDef="let element"> {{ searchItemExtra(69, element.data_extra).text }} </td>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="type == 57;">
                    <ng-container matColumnDef="Sistólica">
                        <th mat-header-cell *matHeaderCellDef> Sistólica </th>
                        <td mat-cell *matCellDef="let element"> {{ searchItemExtra(57, element.data_extra).text  }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Diastólica">
                        <th mat-header-cell *matHeaderCellDef> Diastólica </th>
                        <td mat-cell *matCellDef="let element"> {{ searchItemExtra(25, element.data_extra).text }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Pulso">
                        <th mat-header-cell *matHeaderCellDef> Pulso </th>
                        <td mat-cell *matCellDef="let element"> {{ searchItemExtra(46, element.data_extra).text  }} </td>
                    </ng-container>
                </ng-container>
                <ng-container matColumnDef="Fecha">
                    <th mat-header-cell *matHeaderCellDef> Fecha Medición </th>
                    <td mat-cell *matCellDef="let element"> {{element.dateMeasurements}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;"></tr>
            </table>
            @if(loading) {
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            }
            <mat-paginator [ngStyle]="{'color':'#000'}" (page)="handlePageEvent($event)" [length]="totalItems"
                [pageSize]="itemsPerPage" [pageIndex]="currentPage" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                aria-label="Seleccionar página">
            </mat-paginator>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="dismiss()" type="button" class="button-app button-app--first-color ">Cerrar</button>
</mat-dialog-actions>