<div class="page-content">
    <mat-tab-group animationDuration="0ms" dynamicHeight class="pb-2" (selectedTabChange)="tabSelectedParent($event)">
        <mat-tab label="Resumen">
            <div class="azh-col-row center pb-4">
                <div class="col--12 pt-1 pl-1">
                    <h3 class="left bold">Promedio últimos 30 días</h3>
                </div>
                <div class="azh-col-row fs-18 center">
                    <div class="shadowBox col--11">
                        <div class="col--6 " *ngIf="stateChart.chart1 == true">
                            <div class="mediaLabel" >
                                <strong>{{summaryData.mediaGlobal.split(' ')[0] || ''}}</strong><br>
                                <span>{{summaryData.mediaGlobal.split(' ')[1] || ''}}</span>
                            </div>
                            <ng-container *ngIf="stateChart.chart1 == true">
                                <ngx-charts-pie-chart [view]="[180,180]" [scheme]="colorSchemeResumen"
                                    [results]="series" [legend]="false" [labels]="false" [doughnut]="true">
                                </ngx-charts-pie-chart>
                            </ng-container>
                        </div>
                        <div class="col--6 center contentPieChar" *ngIf="!stateChart.chart1">
                            <em class="fa fa-circle-o-notch fa-spin fa-8x"></em>
                        </div>
                        <div class="col--6 left pl-2 pt-1">
                            <div class="notNormalItem timeMeasure"></div> Muy alto<br>
                            <div class="highItem timeMeasure mt-2"></div> Alto<br>
                            <div class="normalItem timeMeasure mt-2"></div> Normal<br>
                            <div class="lowItem timeMeasure mt-2"></div> Bajo
                        </div>
                        <div class="col--6 pt-2 pb-2">
                            <span>Mayor</span><br>
                            <strong *ngIf="stateChart.chart1">{{summaryData.highValue || ''}}</strong>
                            <em class="fa fa-circle-o-notch fa-spin" *ngIf="!stateChart.chart1"></em>
                        </div>
                        <div class="col--6 pt-2 pb-2">
                            <span>Menor</span><br>
                            <strong *ngIf="stateChart.chart1">{{summaryData.lowValue || ''}}</strong>
                            <em class="fa fa-circle-o-notch fa-spin" *ngIf="!stateChart.chart1"></em>
                        </div>
                    </div>
                </div>
                <div class="col--12 pt-2 pl-1">
                    <h3 class="left bold">Promedio por momento de medición</h3>
                </div>
                <div class="azh-col-row fs-18" *ngIf="stateChart.chart1">
                    <hr>
                    <div class="col--8 left pl-1 pb-1">
                        <div [class]="'timeMeasure ' + summaryData.measurementMoment.fasting.classified + 'Item'"></div>
                        Ayuno
                    </div>
                    <div class="col--4 right pr-1 pb-1">
                        <strong>{{summaryData.measurementMoment.fasting.media.split(' ')[0] || ''}}</strong>
                        {{summaryData.measurementMoment.fasting.media.split(' ')[1] || ''}}
                    </div>
                    <hr>
                    <div class="col--8 left pl-1 pb-1">
                        <div
                            [class]="'timeMeasure ' + summaryData.measurementMoment.before_breakfast.classified + 'Item'">
                        </div>
                        Antes del desayuno
                    </div>
                    <div class="col--4 right pr-1 pb-1">
                        <strong>{{summaryData.measurementMoment.before_breakfast.media.split(' ')[0] || ''}}</strong>
                        {{summaryData.measurementMoment.before_breakfast.media.split(' ')[1] || ''}}
                    </div>
                    <hr>
                    <div class="col--8 left pl-1 pb-1">
                        <div
                            [class]="'timeMeasure ' + summaryData.measurementMoment.after_breakfast.classified + 'Item'">
                        </div>
                        Después del desayuno
                    </div>
                    <div class="col--4 right pr-1 pb-1">
                        <strong>{{summaryData.measurementMoment.after_breakfast.media.split(' ')[0] || ''}}</strong>
                        {{summaryData.measurementMoment.after_breakfast.media.split(' ')[1] || ''}}
                    </div>
                    <hr>
                    <div class="col--8 left pl-1 pb-1">
                        <div [class]="'timeMeasure ' + summaryData.measurementMoment.before_lunch.classified + 'Item'">
                        </div>
                        Antes de almuerzo
                    </div>
                    <div class="col--4 right pr-1 pb-1">
                        <strong>{{summaryData.measurementMoment.before_lunch.media.split(' ')[0] || ''}}</strong>
                        {{summaryData.measurementMoment.before_lunch.media.split(' ')[1] || ''}}
                    </div>
                    <hr>
                    <div class="col--8 left pl-1 pb-1">
                        <div [class]="'timeMeasure ' + summaryData.measurementMoment.after_lunch.classified + 'Item'">
                        </div>
                        Después de almuerzo
                    </div>
                    <div class="col--4 right pr-1 pb-1">
                        <strong>{{summaryData.measurementMoment.after_lunch.media.split(' ')[0] || ''}}</strong>
                        {{summaryData.measurementMoment.after_lunch.media.split(' ')[1] || ''}}
                    </div>
                    <hr>
                    <div class="col--8 left pl-1 pb-1">
                        <div [class]="'timeMeasure ' + summaryData.measurementMoment.before_dinner.classified + 'Item'">
                        </div>
                        Antes de cenar
                    </div>
                    <div class="col--4 right pr-1 pb-1">
                        <strong>{{summaryData.measurementMoment.before_dinner.media.split(' ')[0] || ''}}</strong>
                        {{summaryData.measurementMoment.before_dinner.media.split(' ')[1] || ''}}
                    </div>
                    <hr>
                    <div class="col--8 left pl-1 pb-1">
                        <div [class]="'timeMeasure ' + summaryData.measurementMoment.after_dinner.classified + 'Item'">
                        </div>
                        Después de cenar
                    </div>
                    <div class="col--4 right pr-1 pb-1">
                        <strong>{{summaryData.measurementMoment.after_dinner.media.split(' ')[0] || ''}}</strong>
                        {{summaryData.measurementMoment.after_dinner.media.split(' ')[1] || ''}}
                    </div>
                    <hr>
                </div>
                <div class="col--11" *ngIf="!stateChart.chart1">
                    <mat-progress-bar mode="indeterminate" ></mat-progress-bar>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Gráficos">
            <mat-tab-group [selectedIndex]="selected" (selectedIndexChange)="selected = $event;"
                (selectedTabChange)="tabSelected($event)">
                <mat-tab label="7 Días">
                </mat-tab>
                <mat-tab label="30 Días">
                </mat-tab>
                <mat-tab label="90 Días">
                </mat-tab>
            </mat-tab-group>
            <div class="azh-col-row center pb-4">
                <div class="col--11">
                    <h3 class="left bold">Glucosa por fecha</h3>
                </div>
                <div class="col--11 shadowBox p-1">
                    <div class="col--12">
                        <div class="col--12 maxChartLine ">
                            <ng-container *ngIf="stateChart.chart2 == true">
                                <ngx-charts-area-chart class="frontLine" [customColors]="customColors" [legend]="legend"
                                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis"
                                    [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                                    [timeline]="timeline" [results]="multi['glucose_date']" [xAxisTicks]="xAxisTicksDay"
                                    [yAxisTicks]="[rangeChart.chart2.min, rangeChart.chart2.max]"
                                    [yScaleMin]="rangeChart.chart2.min" [yScaleMax]="rangeChart.chart2.max"
                                    [gradient]="true">
                                </ngx-charts-area-chart>
                            </ng-container>
                            <div class="col--12 pt-4" *ngIf="!stateChart.chart2">
                                <em class="fa fa-circle-o-notch fa-spin fa-5x "></em>
                            </div>
                        </div>
                    </div>
                    <div class="col--12">
                        <div class="col--6 left">
                            <div class="normalItem timeMeasure wh8"></div> Antes de comer
                        </div>
                        <div class="col--6 right">
                            <div class="lowItem timeMeasure wh8"></div> Despues de comer
                        </div>
                    </div>
                </div>
                <div class="col--11 mt-1">
                    <h3 class="left bold">Glucosa por hora del día</h3>
                </div>
                <div class="col--11 shadowBox p-1">
                    <div class="col--12">
                        <div class="col--12 maxChartLine ">
                            <ng-container *ngIf="stateChart.chart3 == true">
                                <ngx-charts-line-chart class="frontLine" [scheme]="colorScheme" [legend]="legend"
                                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis"
                                    [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                                    [timeline]="timeline" [results]="multi['glucose_hour']"
                                    [xAxisTicks]="['00', '04', '08', '12', '16', '20', '23']"
                                    [yAxisTicks]="[rangeChart.chart3.min, rangeChart.chart3.max]"
                                    [yScaleMin]="rangeChart.chart3.min" [yScaleMax]="rangeChart.chart3.max">
                                </ngx-charts-line-chart>
                            </ng-container>
                            <div class="col--12 pt-4" *ngIf="!stateChart.chart3">
                                <em class="fa fa-circle-o-notch fa-spin fa-5x "></em>
                            </div>
                        </div>
                    </div>
                    <div class="col--12">
                        <div class="col--6 left">
                            <div class="normalItem timeMeasure wh8"></div> Antes de comer
                        </div>
                        <div class="col--6 right">
                            <div class="lowItem timeMeasure wh8"></div> Despues de comer
                        </div>
                    </div>
                </div>
                <div class="col--11 mt-1">
                    <h3 class="left bold">Tendencias antes de comer</h3>
                </div>
                <div class="col--11 shadowBox p-1">
                    <div class="col--12">
                        <div class="col--12 maxChartLine ">
                            <ng-container *ngIf="stateChart.chart4 == true">
                                <ngx-charts-line-chart class="frontLine" [scheme]="colorScheme" [legend]="legend"
                                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis"
                                    [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                                    [timeline]="timeline" [results]="multi['glucose_before_eating']"
                                    [xAxisTicks]="xAxisTicksDayBefore"
                                    [yAxisTicks]="[rangeChart.chart4.min, rangeChart.chart4.max]"
                                    [yScaleMin]="rangeChart.chart4.min" [yScaleMax]="rangeChart.chart4.max">
                                </ngx-charts-line-chart>
                            </ng-container>
                            <div class="col--12 pt-4" *ngIf="!stateChart.chart4">
                                <em class="fa fa-circle-o-notch fa-spin fa-5x "></em>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col--11 mt-1">
                    <h3 class="left bold">Tendencias despues de comer</h3>
                </div>
                <div class="col--11 shadowBox p-1">
                    <div class="col--12">
                        <div class="col--12 maxChartLine ">
                            <ng-container *ngIf="stateChart.chart5 == true">
                                <ngx-charts-line-chart class="frontLine" [scheme]="colorSchemeBefore" [legend]="legend"
                                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis"
                                    [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                                    [timeline]="timeline" [results]="multi['glucose_after_eating']"
                                    [xAxisTicks]="xAxisTicksDayAfter"
                                    [yAxisTicks]="[rangeChart.chart5.min, rangeChart.chart5.max]"
                                    [yScaleMin]="rangeChart.chart5.min" [yScaleMax]="rangeChart.chart5.max">
                                </ngx-charts-line-chart>
                            </ng-container>
                            <div class="col--12 pt-4" *ngIf="!stateChart.chart5">
                                <em class="fa fa-circle-o-notch fa-spin fa-5x "></em>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Listado">
            @if (itemList.length > 0) {
            <div class="azh-col-row center pb-4" infiniteScroll [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="300" (scrolled)="onScroll()">
                <div class="col--12 itemList" *ngFor="let item of itemList; let i = index">
                    <div class="col--12 itemHeader left">
                        {{ item.date }}
                    </div>
                    <div class="col--3 itemMeasure">
                        <div [class]="item.labelHealth +'BorderItem'">
                            <strong>{{ item.value }}</strong><br>
                            {{ item.unidad }}
                        </div>
                    </div>
                    <div class="col--6 itemMeasureExtra left">
                        <strong>{{ item.type_tm }}</strong><br>
                        {{ item.optional_note }}
                    </div>
                    <div class="col--3 itemTime">
                        {{ item.time }}
                    </div>
                </div>
                <div class="col--12 pt-1" *ngIf="loadingScroll"><em class="fa fa-circle-o-notch fa-spin fa-2x"></em></div>
            </div>
            } @else {
            <div class="azh-col-row center">
                <div class="col--12 pt-4" *ngIf="loadingScroll">
                    <em class="fa fa-circle-o-notch fa-spin fa-2x"></em>
                </div>
                <div class="col--12 pt-4 " *ngIf="!loadingScroll">
                    <em class="fas fa-4x fa-table opacity-65"></em>
                    <h2 class="pt-1 opacity-65">Sin mediciones registradas</h2>
                </div>
            </div>
            }
        </mat-tab>
    </mat-tab-group>
</div>