<div class="page-content">
    <mat-tab-group animationDuration="0ms" dynamicHeight class="pb-2">
        <mat-tab label="Gráficos">
            <mat-tab-group [selectedIndex]="selected" (selectedIndexChange)="changeFilters($event)">
                <mat-tab label="7 Días">
                </mat-tab>
                <mat-tab label="30 Días">
                </mat-tab>
                <mat-tab label="Año">
                </mat-tab>
            </mat-tab-group>
            <div
                [class]="'azh-col-row center paddingChart ' + ((selected == 2) ? 'no-visible':'') + ((stateChart.chart3 == false) ? ' mb-4 pb-5':'')">
                <div class="col--12 mt-1">
                    <h2 class="left bold">Promedio diario</h2>
                </div>
                <div class="col--12">
                    <h3 class="left bold">Presión sistólica y diastólica (mmHg) </h3>
                </div>
                <div class="col--12 pb-1 shadow">
                    <div class="col--12">
                        <div class="col--12 maxChartLine height-150">
                            <ng-container *ngIf="stateChart.chart1 == true">
                                <ngx-charts-bubble-chart class="frontLine" [customColors]="customColors"
                                    [legend]="legend" [showXAxisLabel]="showXAxisLabel"
                                    [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis" [yAxis]="yAxis"
                                    [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [results]="multiDay"
                                    [minRadius]="minRadius" [maxRadius]="maxRadius"
                                    [yAxisTicks]="[rangeChart.chart1.min, rangeChart.chart1.max]"
                                    [yScaleMin]="rangeChart.chart1.min" [yScaleMax]="rangeChart.chart1.max">
                                    <ng-template #tooltipTemplate let-model="model">
                                        <span class="tooltip-label">{{model.name}} <em class="fa fa-clock"></em></span>
                                        <span class="tooltip-val">{{ types[model.series] }}</span>
                                        <span class="tooltip-val">{{ model.value | number }}</span>
                                    </ng-template>
                                </ngx-charts-bubble-chart>
                            </ng-container>
                            <div class="col--12 pt-4" *ngIf="!stateChart.chart1">
                                <em class="fa fa-circle-o-notch fa-spin fa-5x "></em>
                            </div>
                        </div>
                    </div>
                    <div class="col--12 left pl-2">
                        <div class="presionnormal timeMeasure mt-1"></div> Normal<br>
                        <div class="presionhight timeMeasure mt-1"></div> Elevada<br>
                        <div class="presionstage1 timeMeasure mt-1"></div> Hipertensión etapa 1<br>
                        <div class="presionstage2 timeMeasure mt-1"></div> Hipertensión etapa 2<br>
                        <div class="presionlow timeMeasure mt-1"></div> Crisis hipertensiva
                    </div>
                </div>
                <div class="col--12 maxChartLine pb-4 height-120 mt-1 mb-3">

                    <h3 class="left bold">Presión por hora del día (mmHg) </h3>
                    <div class="col--12 chartLine height-120">
                    </div>
                    <ng-container *ngIf="stateChart.chart2 == true">
                        <ngx-charts-bubble-chart class="frontLine" [customColors]="customColors" [legend]="legend"
                            [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis"
                            [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [results]="multiTime"
                            [minRadius]="minRadius" [maxRadius]="maxRadius" [xAxisTicks]="customTicksX"
                            [yAxisTicks]="[rangeChart.chart2.min, rangeChart.chart2.max]"
                            [yScaleMin]="rangeChart.chart2.min" [yScaleMax]="rangeChart.chart2.max">
                            <ng-template #tooltipTemplate let-model="model">
                                <span class="tooltip-label">{{model.name}} <em class="fa fa-clock"></em></span>
                                <span class="tooltip-val">{{ types[model.series] }}</span>
                                <span class="tooltip-val">{{ model.value | number }}</span>
                            </ng-template>
                        </ngx-charts-bubble-chart>
                    </ng-container>
                    <div class="col--12 pt-3" *ngIf="!stateChart.chart2">
                        <em class="fa fa-circle-o-notch fa-spin fa-5x "></em>
                    </div>
                </div>
                <div class="col--12 maxChartLine mt-2 mb-4" style="max-height: 220px;">
                    <h3 class="left bold">Pulso en reposo (ipm)</h3>
                    <div class="col--12 chartLine">
                        <div class="timeMeasureLeft">
                            <div class="normalItem timeMeasure wh8"></div> Normal
                        </div>
                        <div class="timeMeasureRight">
                            <div class="highItem timeMeasure wh8"></div> Inadecuado
                        </div>
                    </div>
                    <ng-container *ngIf="stateChart.chart3 == true">
                        <ngx-charts-area-chart class="frontLine" [customColors]="customColors" [legend]="legend"
                            [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="true" [xAxis]="xAxis" [yAxis]="yAxis"
                            [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline" [results]="multi"
                            [yAxisTicks]="[rangeChart.chart3.min, rangeChart.chart3.max]"
                            [yScaleMin]="rangeChart.chart3.min" [yScaleMax]="rangeChart.chart3.max" [gradient]="true">
                            <ng-template #tooltipTemplate let-model="model">
                                <span class="tooltip-label">{{model.name}} <em class="fa fa-calendar"></em></span>
                                <span class="tooltip-val">{{ typesPulse[model.series] }}</span>
                                <span class="tooltip-val">{{ model.value | number }}</span>
                            </ng-template>
                        </ngx-charts-area-chart>
                    </ng-container>
                    <div class="col--12 pt-3" *ngIf="!stateChart.chart3">
                        <em class="fa fa-circle-o-notch fa-spin fa-5x "></em>
                    </div>
                </div>
            </div>
            <div
                [class]="'azh-col-row center paddingChart ' + ((selected == 0 || selected == 1) ? 'no-visible':'') + ((stateChart.chart6 == false) ? ' mb-4 pb-5':'')">
                <div class="col--12 mt-1 pl-1">
                    <h2 class="left bold">Promedio mensual</h2>
                </div>
                <div class="col--12">
                    <h3 class="left bold">Presión sistólica y diastólica (mmHg) </h3>
                </div>
                <div class="col--12 pb-1 shadow">
                    <div class="col--12">
                        <div class="col--12 maxChartLine height-150">
                            <ng-container *ngIf="stateChart.chart4 == true">
                                <ngx-charts-bubble-chart class="frontLine" [customColors]="customColors"
                                    [legend]="legend" [showXAxisLabel]="showXAxisLabel"
                                    [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis" [yAxis]="yAxis"
                                    [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [results]="multiDayYear"
                                    [minRadius]="minRadius" [maxRadius]="maxRadius"
                                    [yAxisTicks]="[rangeChart.chart4.min, rangeChart.chart4.max]"
                                    [yScaleMin]="rangeChart.chart4.min" [yScaleMax]="rangeChart.chart4.max">
                                    <ng-template #tooltipTemplate let-model="model">
                                        <span class="tooltip-label">{{model.label}} <em class="fa fa-clock"></em></span>
                                        <span class="tooltip-val">{{ types[model.series] }}</span>
                                        <span class="tooltip-val">{{ model.value | number }}</span>
                                    </ng-template>
                                </ngx-charts-bubble-chart>
                            </ng-container>
                            <div class="col--12 pt-4" *ngIf="!stateChart.chart4">
                                <em class="fa fa-circle-o-notch fa-spin fa-5x "></em>
                            </div>
                        </div>
                    </div>
                    <div class="col--12 left pl-2">
                        <div class="presionnormal timeMeasure mt-1"></div> Normal<br>
                        <div class="presionhight timeMeasure mt-1"></div> Elevada<br>
                        <div class="presionstage1 timeMeasure mt-1"></div> Hipertensión etapa 1<br>
                        <div class="presionstage2 timeMeasure mt-1"></div> Hipertensión etapa 2<br>
                        <div class="presionlow timeMeasure mt-1"></div> Crisis hipertensiva
                    </div>
                </div>
                <div class="col--12 maxChartLine pb-4 height-120 mt-1 mb-3">

                    <h3 class="left bold">Presión por hora del día (mmHg) </h3>
                    <div class="col--12 chartLine height-120">
                    </div>
                    <ng-container *ngIf="stateChart.chart5 == true">
                        <ngx-charts-bubble-chart class="frontLine" [customColors]="customColors" [legend]="legend"
                            [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis"
                            [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                            [results]="multiTimeYear" [minRadius]="minRadius" [maxRadius]="maxRadius"
                            [xAxisTicks]="customTicksX" [yAxisTicks]="[rangeChart.chart5.min, rangeChart.chart5.max]"
                            [yScaleMin]="rangeChart.chart5.min" [yScaleMax]="rangeChart.chart5.max">
                            <ng-template #tooltipTemplate let-model="model">
                                <span class="tooltip-label">{{model.name}} <em class="fa fa-clock"></em></span>
                                <span class="tooltip-val">{{ types[model.series] }}</span>
                                <span class="tooltip-val">{{ model.value | number }}</span>
                            </ng-template>
                        </ngx-charts-bubble-chart>
                    </ng-container>
                    <div class="col--12 pt-4" *ngIf="!stateChart.chart5">
                        <em class="fa fa-circle-o-notch fa-spin fa-5x "></em>
                    </div>
                </div>
                <div class="col--12 maxChartLine mt-2 mb-4" style="max-height: 220px;">
                    <h3 class="left bold">Pulso en reposo (ipm)</h3>
                    <div class="col--12 chartLine">
                        <div class="timeMeasureLeft">
                            <div class="normalItem timeMeasure wh8"></div> Normal
                        </div>
                        <div class="timeMeasureRight">
                            <div class="highItem timeMeasure wh8"></div> Inadecuado
                        </div>
                    </div>
                    <ng-container *ngIf="stateChart.chart6 == true">
                        <ngx-charts-area-chart class="frontLine" [customColors]="customColors" [legend]="legend"
                            [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="false" [xAxis]="xAxis" [yAxis]="yAxis"
                            [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline"
                            [results]="multiYear" [yAxisTicks]="[rangeChart.chart6.min, rangeChart.chart6.max]"
                            [yScaleMin]="rangeChart.chart6.min" [yScaleMax]="rangeChart.chart6.max">
                            <ng-template #tooltipTemplate let-model="model">
                                <span class="tooltip-label">{{model.name}} <em class="fa fa-calendar"></em></span>
                                <span class="tooltip-val">{{ typesPulse[model.series] }}</span>
                                <span class="tooltip-val">{{ model.value | number }}</span>
                            </ng-template>
                        </ngx-charts-area-chart>
                    </ng-container>
                    <div class="col--12 pt-4" *ngIf="!stateChart.chart6">
                        <em class="fa fa-circle-o-notch fa-spin fa-5x "></em>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Listado">
            @if (itemList.length > 0) {
            <div class="azh-col-row center paddingChart pb-4" infiniteScroll [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="300" (scrolled)="onScroll()">
                <div class="col--12 itemList" *ngFor="let item of itemList; let i = index">
                    <div class="col--12 itemHeader left">
                        {{ item.date }}
                    </div>
                    <div class="col--1 itemMeasureExtra">
                        <div [class]="'presion' + item.labelHealth"></div>
                    </div>
                    <div class="col--8 left itemMeasure">
                        <div>
                            {{ extraerDespliegue(item.despliegue) }}<br>
                            <strong>{{ item.value }}</strong><br>
                            {{ item.unidad }}
                        </div>
                        <div *ngFor="let extra of item.data_extra; let ii = index">
                            {{ extraerDespliegue(extra.despliegue) }}<br>
                            <strong>{{ extra.value }}</strong><br>
                            {{ extra.unidad }}
                        </div>
                    </div>
                    <div class="col--3 itemTime right">
                        {{ item.time }}
                    </div>
                    <div class="col--12 itemFooter left" *ngIf="item.optional_note!=''">
                        <em class="fa fa-sticky-note-o"></em> {{ item.optional_note }}
                    </div>
                </div>
                <div *ngIf="loadingScroll" class="col--12 pt-1" >
                    <em class="fa fa-circle-o-notch fa-spin fa-2x"></em>
                </div>
            </div>
            } @else {
            <div class="azh-col-row center">
                <div class="col--12 pt-4 " *ngIf="!loadingScroll">
                    <em class="fas fa-4x fa-table opacity-65"></em>
                    <h2 class="pt-1 opacity-65">Sin mediciones registradas</h2>
                </div>
                <div class="col--12 pt-4" *ngIf="loadingScroll"><em class="fa fa-circle-o-notch fa-spin fa-2x"></em></div>
            </div>
            }
        </mat-tab>
    </mat-tab-group>
</div>