@if (loading) {
<az-loader-fullscreen [show-loading]="true"></az-loader-fullscreen>
} @else {
<div class="page-content">
    <div class="azh-col-row">
        <div class="azh-col--12">
            <div class="container" id="content-to-card">
                <div class="button-exit" (click)="authService.logout(true)">
                    <span class="tooltip-dark" az-tooltip>
                        <em class="fas fa-sign-out-alt"></em>
                        <az-tooltip azTooltipText="Cerrar sesión" azTooltipPosition="left"></az-tooltip>
                    </span>
                </div>
                <div class="azh-col-row p-0">
                    <div class="azh-col--2 borderFicha p-0 center">
                        <img [src]="paciente.avatar !='' ? paciente.avatar : 'assets/img/avatar.png'"
                                    alt="avatar" class="avatar-beneficiary">
                    </div>

                    <div class="azh-col--3 borderFicha">
                        <strong class="title-card-info">{{paciente.nombreCompleto}}</strong> <br>
                        <span >
                            <em [class]="'fa fa-' + getSex"></em>
                            {{paciente.edad}}
                        </span>
                    </div>

                    <div class="azh-col--2 borderFicha">
                        <strong class="title-card-info">Contacto</strong>
                        <br>
                        <span ><em class="fas fa-envelope"></em> {{paciente.email}}</span>
                        <br>
                        <span ><em class="fas fa-phone"></em> {{paciente.telefono}}</span>
                    </div>

                    <div class="azh-col--2 borderFicha">
                        <strong class="title-card-info">Último examen</strong>
                        <br>
                        <span >{{paciente.date_last_test_format}}</span>
                    </div>

                    <div class="azh-col--3 borderFicha">
                        @if (paciente.last_medition != null) {
                        <strong class="title-card-info" (click)="openHistorialDialog(paciente.last_medition.tipo_metric)">{{ paciente.last_medition.despliegue }}</strong>
                        <br>
                        <span class="last-medition" (click)="openHistorialDialog(paciente.last_medition.tipo_metric)">{{ paciente.last_medition.value }} {{ paciente.last_medition.unidad
                            }}
                        </span>
                        <br>
                        <span class="last-medition" (click)="openHistorialDialog(paciente.last_medition.tipo_metric)">
                            {{ paciente.last_medition.date }} - {{ paciente.last_medition.time }}
                            <span class="tooltip-dark" az-tooltip>
                                <em class="fas fa-list color-sec"></em>
                                <az-tooltip azTooltipText="Ver historial" azTooltipPosition="top"></az-tooltip>
                            </span>
                        </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="azh-col-row">
        <div class="azh-col--12">
            <div class="container" id="content-to-download">
                <div class="azh-col-row">
                    <div class="azh-col--4 azh-col--mobile-12 pl-0">
                        <div class="card-app borderFicha borderFichaMax">
                            <div class="card-app__body">
                                <div class="azh-col-row borderBottom">
                                    <div class="azh-col--7 azh-col--mobile-12">
                                        <p class="p-title-card"><strong>Peso</strong></p>
                                    </div>
                                    @if (paciente.meditionsLast.last_medition_a != null) {
                                    <div class="azh-col--5 azh-col--mobile-12" (click)="openHistorialDialog('weight')">
                                        <strong>Últ. Medición:</strong>
                                    </div>
                                    <div class="azh-col--12 azh-col--mobile-12 pt-0" (click)="openHistorialDialog('weight')">
                                        <p class="p-title-card text-right">
                                            {{paciente.meditionsLast.last_medition_a.date}} -
                                            {{paciente.meditionsLast.last_medition_a.time}}
                                            <span az-tooltip class="tooltip-dark">
                                                <em class="fas fa-list"></em>
                                                <az-tooltip azTooltipPosition="bottom"
                                                    azTooltipText="Ver historial"></az-tooltip>
                                            </span>
                                        </p>
                                    </div>
                                    }
                                </div>
                                <div class="azh-col-row borderBottom flex xCenter yCenter content-mid borderBottomMin">
                                    <div class="azh-col--4 azh-col--mobile-12 center">
                                        <strong class="nr-valores">{{ paciente.meditionsLast.last_medition_a?
                                            paciente.meditionsLast.last_medition_a.value : '-' }}</strong>
                                        <span >{{ paciente.meditionsLast.last_medition_a?
                                            paciente.meditionsLast.last_medition_a.unidad : '-' }}</span>
                                    </div>
                                    <div class="azh-col--4 azh-col--mobile-12 center">
                                        <strong class="nr-valores">
                                            {{ getDataExtraArray(paciente.meditionsLast.last_medition_a,'Índice de masa corporal (IMC)', 'value') }}
                                        </strong>
                                        <span
                                            >{{getDataExtraArray(paciente.meditionsLast.last_medition_a,'Índice de masa corporal (IMC)','unidad')}}</span>
                                    </div>
                                    @if (paciente.meditionsLast.last_medition_a != null) {
                                    <div class="azh-col--4 azh-col--mobile-12">
                                        @if (paciente.meditionsLast.last_medition_a.labelHealth &&
                                        paciente.meditionsLast.last_medition_a.labelHealth=='low') {
                                        <div class="alert-color color-1">Insuficiente</div>
                                        }
                                        @if (paciente.meditionsLast.last_medition_a.labelHealth &&
                                        paciente.meditionsLast.last_medition_a.labelHealth=='normal') {
                                        <div class="alert-color color-2">Normal</div>
                                        }
                                        @if (paciente.meditionsLast.last_medition_a.labelHealth &&
                                        paciente.meditionsLast.last_medition_a.labelHealth=='notNormal') {
                                        <div class="alert-color color-3">Sobrepeso</div>
                                        }
                                        @if (paciente.meditionsLast.last_medition_a.labelHealth &&
                                        paciente.meditionsLast.last_medition_a.labelHealth=='high') {
                                        <div class="alert-color color-4">Obesidad</div>
                                        }
                                    </div>
                                    }
                                </div>
                                <div class="azh-col-row">
                                    <div class="azh-col--12 m-4 center">
                                        <button type="button" class="button-app button-app--second-color"
                                            (click)="openWeightDialog()">Ingresar
                                            Medición</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="azh-col--4 azh-col--mobile-12 pl-0 pr-0">
                        <div class="card-app borderFicha borderFichaMax">
                            <div class="card-app__body">
                                <div class="azh-col-row borderBottom">
                                    <div class="azh-col--7 azh-col--mobile-12">
                                        <p class="p-title-card"><strong>Glicemia</strong></p>
                                    </div>
                                    @if (paciente.meditionsLast.last_medition_c != null) {
                                        <div class="azh-col--5 azh-col--mobile-12" (click)="openHistorialDialog('glycemia')">
                                            <strong>Últ. Medición:</strong>
                                        </div>
                                        <div class="azh-col--12 azh-col--mobile-12 pt-0" (click)="openHistorialDialog('glycemia')">
                                        <p class="p-title-card text-right">
                                            {{paciente.meditionsLast.last_medition_c.date}} -
                                            {{paciente.meditionsLast.last_medition_c.time}}
                                            <span az-tooltip class="tooltip-dark">
                                                <em class="fas fa-list"></em>
                                                <az-tooltip azTooltipPosition="bottom"
                                                    azTooltipText="Ver historial"></az-tooltip>
                                            </span>
                                        </p>
                                    </div>
                                    }
                                </div>
                                <div class="azh-col-row borderBottom flex xCenter yCenter content-mid borderBottomMin">
                                    <div class="azh-col--3 azh-col--mobile-12 center">
                                        <div class="circle-alert"
                                            [class]="paciente.meditionsLast.last_medition_c? 'circle-alert-' + paciente.meditionsLast.last_medition_c.labelHealth:''">
                                            <strong>{{ paciente.meditionsLast.last_medition_c?
                                                paciente.meditionsLast.last_medition_c.value : '-' }}</strong>
                                            <span>{{ paciente.meditionsLast.last_medition_c?
                                                paciente.meditionsLast.last_medition_c.unidad : '-' }}</span>
                                        </div>
                                    </div>
                                    @if (paciente.meditionsLast.last_medition_c != null) {
                                    <div class="azh-col--4 azh-col--mobile-12">
                                        @if (paciente.meditionsLast.last_medition_c.labelHealth &&
                                        paciente.meditionsLast.last_medition_c.labelHealth=='low') {
                                        <div class="alert-color color-1">Baja</div>
                                        }
                                        @if (paciente.meditionsLast.last_medition_c.labelHealth &&
                                        paciente.meditionsLast.last_medition_c.labelHealth=='normal') {
                                        <div class="alert-color color-2">Normal</div>
                                        }
                                        @if (paciente.meditionsLast.last_medition_c.labelHealth &&
                                        paciente.meditionsLast.last_medition_c.labelHealth=='notNormal') {
                                        <div class="alert-color color-3">Pre-diabetes</div>
                                        }
                                        @if (paciente.meditionsLast.last_medition_c.labelHealth &&
                                        paciente.meditionsLast.last_medition_c.labelHealth=='high') {
                                        <div class="alert-color color-4">Diabetes</div>
                                        }
                                    </div>
                                    }
                                    <div class="azh-col--5 azh-col--mobile-12">
                                        <span >{{ paciente.meditionsLast.last_medition_c?
                                            paciente.meditionsLast.last_medition_c.type_tm : '' }}</span>
                                    </div>
                                </div>
                                <div class="azh-col-row">
                                    <div class="azh-col--12 m-4 center">
                                        <button type="button" class="button-app button-app--second-color"
                                            (click)="openBloodSugarDialog()">Ingresar
                                            Medición</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="azh-col--4 azh-col--mobile-12 p-r-0">
                        <div class="card-app borderFicha borderFichaMax">
                            <div class="card-app__body">
                                <div class="azh-col-row borderBottom">
                                    <div class="azh-col--7 azh-col--mobile-12">
                                        <p class="p-title-card">
                                            <strong>Presión arterial</strong>
                                        </p>
                                    </div>
                                    @if (paciente.meditionsLast.last_medition_b != null) {
                                        <div class="azh-col--5 azh-col--mobile-12" (click)="openHistorialDialog('pressure')">
                                            <strong>Últ. Medición:</strong>
                                        </div>
                                        <div class="azh-col--12 azh-col--mobile-12 pt-0" (click)="openHistorialDialog('pressure')">
                                        <p class="p-title-card text-right">
                                            {{paciente.meditionsLast.last_medition_b.date}} -
                                            {{paciente.meditionsLast.last_medition_b.time}}
                                            <span az-tooltip="" class="tooltip-dark">
                                                <em class="fas fa-list"></em>
                                                <az-tooltip azTooltipPosition="left"
                                                    azTooltipText="Ver historial"></az-tooltip>
                                            </span>
                                        </p>
                                    </div>
                                    }
                                </div>
                                <div class="azh-col-row borderBottom flex xCenter yCenter content-mid borderBottomMin">
                                    <div class="azh-col--3 azh-col--mobile-12 center">
                                        <span >
                                            <strong>Sistólica</strong>
                                        </span>
                                        <strong class="nr-valores">{{ paciente.meditionsLast.last_medition_b?
                                            paciente.meditionsLast.last_medition_b.value : '-' }}</strong>
                                        <span >{{ paciente.meditionsLast.last_medition_b?
                                            paciente.meditionsLast.last_medition_b.unidad : '-' }}</span>
                                    </div>
                                    <div class="azh-col--3 azh-col--mobile-12 center">
                                        <span >
                                            <strong>Diastólica</strong>
                                        </span>
                                        <strong
                                            class="nr-valores">{{getDataExtraArray(paciente.meditionsLast.last_medition_b,'Presión diastólica','value')}}</strong>
                                        <span
                                            >{{getDataExtraArray(paciente.meditionsLast.last_medition_b,'Presión diastólica','unidad')}}</span>
                                    </div>
                                    <div class="azh-col--3 azh-col--mobile-12 center">
                                        <span >
                                            <strong>Pulso</strong>
                                        </span>
                                        <strong
                                            class="nr-valores">{{getDataExtraArray(paciente.meditionsLast.last_medition_b,'Pulso','value')}}</strong>
                                        <span
                                            >{{getDataExtraArray(paciente.meditionsLast.last_medition_b,'Pulso','unidad')}}</span>
                                    </div>

                                    @if (paciente.meditionsLast.last_medition_b != null) {
                                    <div class="azh-col--3 azh-col--mobile-12">
                                        @if (paciente.meditionsLast.last_medition_b.labelHealth &&
                                        paciente.meditionsLast.last_medition_b.labelHealth=='low') {
                                        <div class="alert-color color-1">Leve</div>
                                        }
                                        @if (paciente.meditionsLast.last_medition_b.labelHealth &&
                                        paciente.meditionsLast.last_medition_b.labelHealth=='normal') {
                                        <div class="alert-color color-2">Normal</div>
                                        }
                                        @if (paciente.meditionsLast.last_medition_b.labelHealth &&
                                        paciente.meditionsLast.last_medition_b.labelHealth=='notNormal') {
                                        <div class="alert-color color-3">Elevada</div>
                                        }
                                        @if (paciente.meditionsLast.last_medition_b.labelHealth &&
                                        paciente.meditionsLast.last_medition_b.labelHealth=='high') {
                                        <div class="alert-color color-4">Alta</div>
                                        }
                                    </div>
                                    }
                                </div>
                                <div class="azh-col-row">
                                    <div class="azh-col--12 m-4 center">
                                        <button type="button" class="button-app button-app--second-color"
                                            (click)="openBloodPressureDialog()">Ingresar
                                            Medición</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
}