import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { JsonResult } from '../../shared/interfaces/json-result.interface';
import { AuthService } from '../../login/services/auth.services';

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    private headers!: HttpHeaders;
    private url: string;
    private urlLink: string;


    constructor(
        private _http: HttpClient,
        private authService: AuthService,
    ) {
        this.url = `${environment.URL_FRONT}`;
        this.urlLink = `${environment.URL_LINK}`;
    }

    getDataPatient(idpaciente: number): Observable<JsonResult> | any {
        this.headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.authService._token}`
        });
        return this._http.get<any>(`${this.url}seguimiento/webresources/services.pacientes/detalle-upi/${idpaciente}`, { headers: this.headers });
    }

    postMeasurements(data: any): Observable<JsonResult> | any {
        this.headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.authService._token}`
        });
        let serviceURL = `${this.urlLink}v3/seguimiento/link/services.sync/save`;
        return this._http.post<any>(serviceURL, data, { headers: this.headers });
    }

    getMeasurements(idpaciente: number, type: number, page: number, pagesize: number): Observable<JsonResult> | any {
        this.headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.authService._token}`
        });
        return this._http.get<any>(`${this.url}seguimiento/webresources/services.pacientes/listado-medidiciones-paginados/${idpaciente}/${type}/${((page == 0) ? 1 : (page + 1))}/${pagesize}`, { headers: this.headers });
    }
}
