import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, NO_ERRORS_SCHEMA, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ValidatorsUtils } from "../../../shared/utils/validators.util";
import { HomeService } from "../../services/home.services";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import moment from "moment";
import { HttpClientModule } from "@angular/common/http";

@Component({
    selector: "app-blood-sugar",
    templateUrl: "./blood-sugar.component.html",
    standalone: true,
    imports: [MatDialogModule, CommonModule, FormsModule, ReactiveFormsModule, MatSnackBarModule, HttpClientModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    providers: []
})
export class BloodSugarComponent implements OnInit {

    exMeasurement: string = "^[0-9]{1,3}(\\.[0-9]{1,2})?$";
    form!: FormGroup;
    loadService: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private modalRef: MatDialogRef<BloodSugarComponent>,
        private fb: FormBuilder,
        public validatorUtils: ValidatorsUtils,
        private homeService: HomeService,
        private _snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.form = this.fb.group({
            bloodSugar: ['', [Validators.required, Validators.min(1), Validators.pattern(this.exMeasurement)]],
            timeMeasurement: ['', [Validators.required]],
            commet: ['', [Validators.minLength(3)]]
        });
    }

    submit() {
        let info = this.form;
        let utc: string | number = moment().format('Z');
        utc = Number(utc.replace(':', ''));
        if (utc > 0) {
            utc = utc * -1;
        }

        let data: any = {
            "category": "daily",
            "start_time": moment().format("YYYY-MM-DD HH:mm:ss"),
            "end_time": moment().format("YYYY-MM-DD HH:mm:ss"),
            "metrics": [
                {
                    "origin": "manual",
                    "type": "blood_glucose",
                    "unit": "mg/dL",
                    "value": info.get('bloodSugar')?.value
                }
            ],
            "source": {
                "type": "manual",
                "device": {
                    "model": "manual",
                    "manufacturer": "manual"
                }
            },
            "uid": this.data.data.usuariovalidic,
            "type": "Measurements",
            "optional_note": info.get('commet')?.value,
            "utc_offset": utc,
            "idtimemeasurement": Number(info.get('timeMeasurement')?.value)
        }
        this.loadService = true;
        this.homeService.postMeasurements(data).subscribe(() => {
            this._snackBar.open("Medición de glicemia creada exitosamente", "Ok");
            this.loadService = false;
            this.closeModal(true);
        }, () => {
            this.loadService = false;
        });
    }

    closeModal(value: boolean = false) {
        this.modalRef.close(value);
    }
}