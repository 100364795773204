import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'az-tooltip',
  standalone: true,
  templateUrl: './az-tooltip.component.html',
  styleUrls: ['./az-tooltip.component.css']
})
export class AzTooltipComponent implements OnInit {
  @Input() azTooltipPosition: string = "top";
  @Input() azTooltipText: string = "";

  constructor() {}

  ngOnInit(): void {}
}